<script setup lang="ts">
import { getSeoData, type SeoData } from '~/data/entities';

interface Props {
	handle: string;
	routeName: string;
}

const { $eventClient } = useNuxtApp();
const { handle, routeName } = defineProps<Props>();
const { storeType, store } = useGlobalStore();

const route = useRoute();

let seo: SeoData | undefined = undefined;
try {
	seo = await getSeoData(handle, routeName);
} catch (e) {
	if (e instanceof RedirectError) {
		navigateTo({
			replace: true,
			path: e.location,
			redirectCode: 301,
			query: { ...route.query },
		});
	} else {
		throw createError({
			statusCode: 404,
			message: 'Page not found',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
}

onMounted(() => {
	$eventClient.sendEvent('view-item-list', {
		id: seo?.id || 0,
		title: seo?.title || '',
		productCount: seo?.subtitle || '',
	});
});

useHead({
	title: dropMetaTitle(seo!, store.id, storeType!, store.name!),
	titleTemplate: null,
});
useSeoMeta({
	ogTitle: dropMetaTitle(seo!, store.id, storeType!, store.name!),
	description: dropMetaDesc(seo!, store.id, storeType!, store.name!),
	ogDescription: dropMetaDesc(seo!, store.id, storeType!, store.name!),
});
</script>
<template>
	<slot :seo="seo" />
</template>
